// See more @ https://github.com/laravel/echo

import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

declare const window: any
declare const shared: any

class EchoVendor {
    static init: () => void = () => {
        if (!shared.auth.id) {
            return
        }

        window.Pusher = Pusher
        const options = {
            broadcaster: 'pusher',
            key: shared.pusher.key,
            wsHost: shared.pusher.host,
            wssHost: shared.pusher.host,
            wsPort: shared.pusher.port,
            wssPort: shared.pusher.port,
            cluster: shared.pusher.cluster,
            forceTLS: shared.pusher.force_tls,
        }
        window.Echo = new Echo(options)
    }
}

export default EchoVendor
