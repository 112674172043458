// See more @ https://docs.sentry.io/platforms/javascript

declare const window: any
declare var shared: any

class SentryVendor {
    static init: () => Promise<any> = async () => {
        if (!shared.sentry.dsn) {
            return await Promise.resolve()
        }

        // Config
        const Sentry = await import('@sentry/browser')
        window.Sentry = Sentry

        const tracesSampleRate =
            shared.app.traces_sample_rate &&
            parseFloat(shared.app.traces_sample_rate)

        // Sentry init
        Sentry.init({
            dsn: shared.sentry.dsn,
            environment: shared.app.env,
            tracesSampleRate,
            integrations: [Sentry.browserTracingIntegration()],
        })

        return await Promise.resolve()
    }
}

export default SentryVendor
