// We manually build Livewire assets in order to avoid unwanted behavior on K8s infrastructure
// See more @ https://livewire.laravel.com/docs/installation#manually-bundling-livewire-and-alpine

import { Livewire } from '../../../vendor/livewire/livewire/dist/livewire.esm'

class LivewireVendor {
    static async init() {
        Livewire.start()
    }
}

export default LivewireVendor
