// See more @ https://github.com/simple-uploader/Uploader

declare const window: any
declare const Livewire: any

let loaded: boolean = false

class SimpleUploaderVendor {
    static init: () => void = () => {
        Livewire.on('input:file:uploader:load', async () => {
            if (loaded) {
                Livewire.dispatch('input:file:uploader:loaded')

                return
            }

            loaded = true

            const { default: Uploader } = await import('simple-uploader.js')
            window.Uploader = Uploader

            Livewire.dispatch('input:file:uploader:loaded')
        })
    }
}

export default SimpleUploaderVendor
