import EchoVendor from '../vendor/EchoVendor'
import LivewireVendor from '../vendor/LivewireVendor'
import SentryVendor from '../vendor/SentryVendor'
import SimpleUploaderVendor from '../vendor/SimpleUploaderVendor'

// Static images loading with Vite
import.meta.glob(['../../images/**'])

// Vendor
EchoVendor.init()
LivewireVendor.init()
SimpleUploaderVendor.init()
SentryVendor.init()
